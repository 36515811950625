import React, {useState} from "react";
import "./JobCard.less";
import EmptyLine from "../../ui/EmptyLine";
import {AJButton4} from "../../ui/AJButton3";
import {HCenterContainer} from "../../ui/VCenterContainer";
import {JoinOurTalentV2} from "../common/JoinOurTalent";
import {getJoinedCommunity, getJoinedCommunityEmail, getJoinedCommunityName} from "../../storage/AJStorage";
import AJModalV2 from "../../ui/AJModalV2";
import {jobOpeningVisitor} from "../../http/AJHttp";

export default function JobCard(props) {
    let {title, description, onClick, location, type, href, company} = props
    const [showModal, setShowModal] = useState(false);
    if (description) {
        location = description[0]
        type = description[1]
    }
    const [background, setBackground] = useState('#FFF')
    const [textColor, setTextColor] = useState('#000')

    onClick = onClick || function () {

    }

    return (
            <div className={'jobcard'}>
                <div className={'jobcard-container'}>
                    <EmptyLine height={23}/>
                    <div className={'jobcard-title'}>
                        {title}
                    </div>
                    <EmptyLine height={10}/>
                    <div className={'jobcard-description'}>
                        <div className={'jobcard-description-location'}>
                            {location}
                            <br/>
                        </div>
                    </div>
                    <EmptyLine height={2}/>
                    <AJButton4 title={'Read more'}
                                size={'mini'}
                                width={'100%'}
                                type={'secondary'}
                                height={46}
                                titleStyle={{
                                    fontSize: 24,
                                    fontWeight: 400,
                                    fontFamily: 'InterRegular',
                                    color: textColor
                                }}
                                customStyle={{
                                    borderRadius: 0,
                                    background: background
                                }}
                                onMouseEnter={() => {
                                    setBackground('#000')
                                    setTextColor('#FFF')
                                }}
                                onMouseLeave={() => {
                                    setBackground('#FFF')
                                    setTextColor('#000')
                                }}
                                onClick={async ()=>{
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'custom_event',
                                    'eventCategory': 'apply',
                                    'eventAction': `${title}`,
                                    'eventlabel': `${company}`
                                });
                                if (getJoinedCommunity()) {
                                    // save email and job id
                                    const email = getJoinedCommunityEmail()
                                    const name = getJoinedCommunityName()
                                    window.open(href);
                                    await jobOpeningVisitor(email, name, href, company)
                                }
                                else {
                                    setShowModal(true);
                                }
                    }}/>
                </div>
                <AJModalV2 visible={showModal}
                           wrapClassName={'jobcard-modal ajmodal-v2'}
                           width={367}
                           closable={true}
                           centered={true}
                           onClose={() => {
                               //window.open(href);
                           }}
                >
                    <JoinOurTalentV2 url={href} company={company} onClose={(noRedirect) => {
                        setShowModal(false);
                        if (!noRedirect) {
                            // save email and job id
                            window.open(href);
                        }
                    }}/>
                </AJModalV2>
            </div>
    )
}