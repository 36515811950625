import React, {useState} from "react";
import "./JobCarousel.less";
import SvgLeftIcon from "./SvgLeftIcon";
import SvgRightIcon from "./SvgRightIcon";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import JobCard from "./JobCard";


export default function JobCarousel(props) {
	let {data, company} = props;
    let jobOpenings = [...data];
	let [buttonLState, setButtonLState] = useState(0);
	let [buttonRState, setButtonRState] = useState(jobOpenings.length > 2 ? 1 : 0);

    if (!data || data.length === 0) {
        return (
            <div className={'job-empty-container'}>
                <div className={'job-openings-empty-info'}>
                    <div className={'text'}>We will soon hire amazing people like you.<br/> Follow us and learn more about our values.</div>
                </div>
            </div>
        )
    }

	function onButtonLClickStart(previous) {
        setButtonLState(buttonState => {
            if (buttonState != 2) return 2;
        });
        previous();
    }

    function onButtonLClickEnd() {
        setButtonLState(buttonState => {
            if (buttonState == 2) return 1;
        });
	}

	function onButtonRClickStart(next) {
        setButtonRState(buttonState => {
            if (buttonState != 2) return 2;
        });
        next();
    }

    function onButtonRClickEnd() {
        setButtonRState(buttonState => {
            if (buttonState == 2) return 1;
        });
	}

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">
            <div className={`direction-button left ${buttonLState === 0 ? 'not-show' : ''}`}
                onMouseDownCapture={() => onButtonLClickStart(previous)}
                onMouseUpCapture={() => onButtonLClickEnd()}
            >
				<SvgLeftIcon state={buttonLState}></SvgLeftIcon>
			</div>

			<div className={`direction-button right ${buttonRState === 0 ? 'not-show' : ''}`}
                style={{right: props.rightArrowOffset}}
                onMouseDownCapture={() => onButtonRClickStart(next)}
                onMouseUpCapture={() => onButtonRClickEnd()}
            >
				<SvgRightIcon state={buttonRState}></SvgRightIcon>
			</div>
          </div>
        );
    };

	return (
		<div className={"carousel-container"} style={{"position": "relative"}}>
			<Carousel
				additionalTransfrom={0}
				arrows={false}
                draggable={false}
				centerMode={false}
				className="job-opening-container"
				containerClass="container-padding-bottom"
				customButtonGroup={<ButtonGroup />}
				partialVisible={false}
				focusOnSelect={false}
				infinite={false}
				itemClass="job-item"
				minimumTouchDrag={80}
				pauseOnHover
				renderArrowsWhenDisabled={false}
				renderButtonGroupOutside
				renderDotsOutside={true}
				responsive={{
				desktop: {
					breakpoint: {
					max: 3000,
					min: 0
					},
					items: 2,
					partialVisibilityGutter: 0
				}
				}}
				rewind={false}
				rewindWithAnimation={false}
				rtl={false}
				shouldResetAutoplay
                dotListClass="job-opening-dots"
				showDots={true}
				sliderClass="job-opening-slider"
				slidesToSlide={2}
				swipeable={false}
				afterChange={(previousSlide, state) => {
                    const direction = previousSlide > state.currentSlide ? 'left' : 'right';
                    if (direction === 'left') {
                        if (state.currentSlide == 0) {
                            setButtonLState(0);
                        }
                        setButtonRState(buttonRState => {
                            if(buttonRState===0) return 1;
                        });
                    }
                    else if (direction === 'right') {
                        if ((state.currentSlide+2) == state.totalItems) {
                            setButtonRState(0);
                        }
                        setButtonLState(buttonLState => {
                            if (buttonLState===0) return 1;
                        });
                    }
				}}
			>
				{
                    jobOpenings.map((jobOpening, index) => {
                        if (!jobOpening) {
                            return <div style={{opacity: 0}}>
                                <JobCard/>
                            </div>
                        }
                
                        return (
                            <JobCard
                                title={jobOpening.title || 'Job Title'}
                                href={jobOpening.url}
                                company={company}
                                description={[jobOpening.location || 'Location', 'Full time Remote']}
                            />
                        )
                    })
                }
			</Carousel>
        </div>
	);
};
